<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          :src="getUser.profileUrl"
          :variant="`light-primary`"
          size="90px"
          rounded
        />
      </template>
      <div class="d-flex mb-10 align-items-center">
        <h4 class="mb-0">
          {{ getUser.firstName + " " + getUser.lastName }}
        </h4>
        <b-badge class="ml-1" :variant="statusVariant(getUser.status).color">
          {{ statusVariant(getUser.status).text }}
        </b-badge>
      </div>

      <div class="d-flex flex-wrap mt-1">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            accept="image/png, image/jpeg, image/tiff"
            class="d-none"
            @change="handleFileUpload($event)"
          />
          <span class="d-none d-sm-inline">Загрузить</span>

          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
      <span class="sm-size"
        >(Поддерживаются файлы в формате .jpg, .png, .tiff)
      </span>
    </b-media>

    <validation-observer ref="simpleRules">
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <!-- Field: firstName -->
          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Имя"
              rules="required"
            >
              <b-form-group label="Имя" label-for="firstName">
                <b-form-input
                  id="firstName"
                  :state="errors.length > 0 ? false : null"
                  v-model="getUser.firstName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: lastName -->
          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Фамилия"
              rules="required"
            >
              <b-form-group label="Фамилия" label-for="lastName">
                <b-form-input
                  :state="errors.length > 0 ? false : null"
                  id="lastName"
                  v-model="getUser.lastName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Field: phoneNumber -->
          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Номер"
              rules="required|min:9"
            >
              <!-- <b-form-group label="Номер телефона" label-for="phoneNumber">
                <b-form-input
                  v-mask="'998#########'"
                  :state="errors.length > 0 ? false : null"
                  id="phoneNumber"
                  v-model="getUser.phone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group> -->

              <b-form-group label="Номер телефона" label-for="phoneNumber">
                <div class="position-relative">
                  <masked-input
                    v-model="phone"
                    class="form-control myinput"
                    id="phoneNumber"
                    :state="errors.length > 0 ? false : null"
                    mask="111111111"
                  />
                  <span class="my-prepend">998</span>
                </div>

                <!-- <input
                  v-model="phone"
                  class="form-control"
                  id="phoneNumber"
                  :state="errors.length > 0 ? false : null"
                  v-mask="'998#########'"
                /> -->
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: email -->
          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Почта"
              rules="required|email"
            >
              <b-form-group label="Почта" label-for="email">
                <b-form-input
                  id="email"
                  :state="errors.length > 0 ? false : null"
                  v-model="getUser.email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Язык"
              rules="required"
            >
              <b-form-group label="Язык" label-for="User-lang">
                <v-select
                  v-model="getUser.lang"
                  :options="langs"
                  :clearable="false"
                  :state="errors.length > 0 ? false : null"
                  input-id="User-lang"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="3">
            <validation-provider
              #default="{ errors }"
              name="Статус"
              rules="required"
            >
              <b-form-group label="Статус" label-for="User-status">
                <v-select
                  v-model="getUser.status"
                  :options="statusOptions"
                  :state="errors.length > 0 ? false : null"
                  :clearable="false"
                  input-id="User-status"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12">
            <!-- Action Buttons -->
            <b-button
              @click.prevent="handleSubmit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            >
              Сохранить
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import axiosIns from "@/libs/axios.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MaskedInput from "vue-masked-input";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";

export default {
  components: {
    ToastificationContent,
    BBadge,
    BFormTextarea,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,

    MaskedInput,
  },
  directives: {
    Ripple,
  },
  props: {},
  watch: {
    getUser(val) {
      this.phone = val.phone.substr(3);
    },
  },
  computed: {
    ...mapGetters(["getUser", "getCategories"]),
    statusVariant() {
      const statusVariants = {
        /* eslint-disable key-spacing */
        ACTIVE: {
          color: "light-success",
          text: "Активный",
        },
        DELETED: {
          color: "light-danger",
          text: "Удаленный",
        },
        BLOCKED: {
          color: "light-warning",
          text: "Заблокированный",
        },
        PENDING: {
          color: "light-secondary",
          text: "В ожидании",
        },
        /* eslint-enable key-spacing */
      };

      return (status) => statusVariants[status];
    },
    statusOptions() {
      if (this.getUser.status === "DELETED") {
        return this.statusOptionsForDeletedUsers;
      } else {
        return this.statusOptionsForActiveUsers;
      }
    },
  },
  data() {
    return {
      required,
      email,
      password,
      confirmed,

      file: null,
      fileUrl: "",
      phone: null,
      statusOptionsForActiveUsers: ["ACTIVE", "DELETED", "BLOCKED", "PENDING"],
      statusOptionsForDeletedUsers: ["DELETED"],
      langs: ["RU", "UZ", "EN"],
    };
  },
  methods: {
    async handleFileUpload(event) {
      this.file = event.target.files[0];
      const formData = new FormData();
      formData.append("logo", this.file);
      const response = await axiosIns.post("image/upload/users", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.getUser.profileUrl = response.data.data.imageUrl;
    },
    async handleSubmit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axiosIns
            .put("v1/user/edit", {
              id: this.$router.currentRoute.params.id,
              firstname: this.getUser.firstName,
              lastname: this.getUser.lastName,
              phoneNumber: "998" + this.phone,
              email: this.getUser.email,
              lang: this.getUser.lang,
              profileUrl: this.getUser.profileUrl,
              userState: this.getUser.status,
            })
            .then((response) => {
              if (response.status == 200) {
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: `Успешно`,
                      icon: "CheckCircleIcon",
                      variant: "success",
                      text: `Пользователь был успешно изменен!`,
                    },
                  },
                  {
                    position: "top-right",
                  }
                );
              }
            })
            .catch((error) => {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: `Ошибка`,
                    icon: "XIcon" /* XCircleIcon AlertCircleIcon AlertTriangleIcon */,
                    variant: "danger",
                    text: error.response.data.errorMessage,
                  },
                },
                {
                  position: "top-right",
                }
              );
              if (error.response) {
                // Request made and server responded
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
            })
            .finally(() => {
              this.$router.push({ name: "users" });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.my-prepend {
  position: absolute;
  left: 2rem;
  top: 50%;
  transform: translate(-50%, -49%);
}
.myinput {
  padding-left: 2.9rem;
}
</style>
