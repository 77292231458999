var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-center","centered":"","title":"Добавить мерчант"},on:{"change":_vm.hideModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"res-w-100",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){return cancel()}}},[_vm._v(" Отмена ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"res-mt-2 res-w-100",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Добавить")])])]}}]),model:{value:(_vm.isAddNewUserModalActive),callback:function ($$v) {_vm.isAddNewUserModalActive=$$v},expression:"isAddNewUserModalActive"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название","label-for":"new-merch-title"}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"id":"new-merch-title","placeholder":"Название"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Категория","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Категория","label-for":"new-merch-category"}},[_c('v-select',{attrs:{"id":"new-merch-category","multiple":"","placeholder":"Выберите категории","state":errors.length > 0 ? false : null,"label":"titleRu","options":_vm.getCategories.categories},model:{value:(_vm.selectedCategories),callback:function ($$v) {_vm.selectedCategories=$$v},expression:"selectedCategories"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Лого","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Лого","label-for":"new-merch-logo"}},[_c('b-form-file',{attrs:{"id":"new-merch-logo","state":errors.length > 0 ? false : null,"accept":"image/png, image/jpeg, image/tiff","placeholder":"Выберите изображение...","drop-placeholder":"Drop file here..."},on:{"change":function($event){return _vm.handleFileUpload($event)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('div',[(_vm.imageRequired === 1)?_c('small',{staticClass:"text-danger"},[_vm._v("Поле Лого поддерживает только файлы в формате .jpg, .png, .tiff")]):_vm._e()]),_c('div',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]),_c('span',{staticClass:"sm-size"},[_vm._v("(Поддерживаются файлы в формате .jpg, .png, .tiff) ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Кешбэк","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Кешбэк","label-for":"new-merch-cashback"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],attrs:{"state":errors.length > 0 ? false : null,"id":"new-merch-cashback","type":"number","placeholder":"Кешбэк"},on:{"input":_vm.checkDiscount},model:{value:(_vm.cashbackDiscount),callback:function ($$v) {_vm.cashbackDiscount=$$v},expression:"cashbackDiscount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Описание RU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание RU","label-for":"new-merch-desc-ru"}},[_c('b-form-textarea',{attrs:{"id":"new-merch-desc-ru","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"3"},model:{value:(_vm.descriptionRu),callback:function ($$v) {_vm.descriptionRu=$$v},expression:"descriptionRu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Описание UZ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание UZ","label-for":"new-merch-desc-uz"}},[_c('b-form-textarea',{attrs:{"id":"new-merch-desc-uz","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"3"},model:{value:(_vm.descriptionUz),callback:function ($$v) {_vm.descriptionUz=$$v},expression:"descriptionUz"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Описание EN","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Описание EN","label-for":"new-merch-desc-en"}},[_c('b-form-textarea',{attrs:{"id":"new-merch-desc-en","state":errors.length > 0 ? false : null,"placeholder":"Описание","rows":"3"},model:{value:(_vm.descriptionEn),callback:function ($$v) {_vm.descriptionEn=$$v},expression:"descriptionEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }